import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const CollateralBuilder = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Collateral builder | GlassHive"
                description="With GlassHive’s collateral builder, it’s a simple and fun way to create visual pleasing content with just a few clicks."
            />
            <div className="bulk-action-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text max-width-1100">
                                Create eye-catching collateral
                            </h1>
                            <p className="hero-subtext white-text">
                                A simple and fun way to create content
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'qc2v2z12qa'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/features/collateral-builder/thumbnail.png')}
                                    alt="Collateral builder video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Generate visual collateral
                            </h4>
                            <p>
                                With GlassHive’s collateral builder, you don't
                                have to be a trained designer to easily design
                                amazing collateral.
                            </p>
                            <p>
                                This intuitive feature gives casual and veteran
                                designers alike the tools needed to create
                                designs that accurately represent your business.
                            </p>
                            <p>
                                Watch our video and learn about what the
                                collateral builder can do and the fun you can
                                have building awesome designs. Check it out!
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Completely customizable
                            </h4>
                            <p>
                                Easily add company details to suit the needs of
                                your design.
                            </p>
                            <h4 className="centered-mobile">Simple to use</h4>
                            <p>Quickly and easily create branded collateral.</p>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Start designing Collateral with a Free GlassHive account." />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default CollateralBuilder;
